import "./leftbanner.scss";
import Hero from "./shared/Hero";
import { strings } from "../../../constant/strings";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackToPage from "./shared/BackToPage";
import { loginPageScrollImages } from "./constant";
import { useEffect, useState } from "react";

const Leftbanner = ({ text, backToText, isMailValid, button }) => {
  //custom hooks
  const navigate = useNavigate();
  const isMobile = useSelector((state) => state?.device?.isMobileDevice);

  //image urls
  const BANNER_URL = "/image/login-left-banner.png";
  const BACK_URL = "/image/icons/backArrow.svg";

  //page url
  const PAGE_URL = "https://reachifyme.com/";
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === loginPageScrollImages.length - 1 ? 0 : prevIndex + 1
        );
        setFade(true);
      }, 500);
    }, 7000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className="left-banner-container">
        {loginPageScrollImages.map((image, index) => (
          <div
            className={`left-banner-carousel-img-container ${
              currentIndex === index ? (fade ? "fade-in-login-panel" : "fade-out-login-panel") : ""
            }`}
            key={index}
            style={{ display: currentIndex === index ? "block" : "none" }}
          >
            <img
              src={image.img}
              alt={`login-page-carousel-${index}`}
              style={{objectFit:"contain",height:"100%",width:"100%" }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Leftbanner;
