import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Buttons from "../../../sharedcomponents/custombuttons/Buttons";
import { toast } from "react-toastify";
import { strings } from "../../../constant/strings";
import useSettingsPageApi from "../../settingsPage/apis/settingspageApi";
import Success from "../../../sharedcomponents/Spinner/Success";
import { useNavigate } from "react-router-dom";
import Leftbanner from "../components/leftbanner";
import Hero from "../components/shared/Hero";
import BackToPage from "../components/shared/BackToPage";
import "../pages/login/login.scss";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const { forgetPassword, setNewPassword } = useSettingsPageApi();

  const isMobile = useSelector((state) => state?.device?.isMobileDevice);
  const email = useSelector((state) => state?.userData?.email);
  const timeInterval = useSelector(
    (state) => state?.emailOtp?.verifyEmailOtp?.retryInterval
  );

  const [loader, setLoader] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [toastText, setToastText] = useState("");
  const [timer, setTimer] = useState(timeInterval);
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [mail, setMail] = useState(email ?? "");

  //const image urls
  const BACK_URL = "/image/icons/backArrow.svg";

  //useEffect to toast message and reset it to empty string
  useEffect(() => {
    if (toastText !== "") {
      toast(toastText);
      setToastText("");
    }
  }, [toastText]);

  //useEffect to set timer
  useEffect(() => {
    setTimer(timeInterval);
  }, [timeInterval]);

  //useEfect for timer
  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  //formatted timer
  const formattedtimer = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleOtpSend = () => {
    if (!validEmail(mail)) {
      toast.error("Please enter valid email");
      return;
    }
    console.log(mail);
    forgetPassword({ email: mail, setLoader, setToastText, setIsOtpSent });
  };

  const handleResendOtp = () => {
    forgetPassword({ email, setLoader, setToastText, setIsOtpSent });
    setTimer(timeInterval);
  };
  const handleClose = () => {
    setIsOtpSent(false);
    setIsSuccess(false);
    navigate("/login");
  };

  const handleChange = (e) => {
    e.target.name === "password"
      ? setPassword(e.target.value)
      : setOtp(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setMail(e.target.value);
  };
  const validEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const validPassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[^\s]+$/;
    return regex.test(password) && password.length >= 8;
  };

  const validOtp = (otp) => {
    console.log(otp.length);
    if (otp.length !== 6) {
      return false;
    }
  };
  const handleNewPassWordSet = () => {
    if (!validEmail(mail)) {
      toast.error("Please enter valid email");
      return;
    }

    if (!validPassword(password)) {
      toast.error(
        "Password contains minimum 8 characters, at least one letter and one number"
      );
      return;
    }

    if (validOtp(otp)) {
      toast.error("Otp must be of size 6");
      return;
    }
    setNewPassword({
      email: mail,
      password,
      otp,
      setLoader,
      setToastText,
      setIsSuccess,
    });
  };
  return (
    <div className="login-hero">
      <div className="main-main-container">
        <Box
          sx={{
            width: isMobile ? "100%" : "500px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "0px",
            padding: isMobile ? "0 5%" : "",
          }}
        >
          <Hero />
          <Box
            sx={{
              fontSize: isMobile ? "20px" : "24px",
              marginTop: "20%",
              textAlign: "center",
              marginTop: isMobile ? "50%" : "48px",
              fontWeight: 400,
              
            }}
          >
            {isOtpSent && !isSuccess
              ? "Enter New Password"
              : !isSuccess && "Forgot password?"}
          </Box>
          {!isOtpSent && (
            <Box
              sx={{
                fontSize: isMobile ? "12px" : "14px",
                marginTop: isMobile ? "7px" : "0px",
                textAlign: "center",
                color: "#4D4D4D",
                fontWeight: "400",
              }}
            >
              {strings.ForgetPassword.EnterMailText}
            </Box>
          )}

          {!isOtpSent && !isSuccess && (
            <Box
              sx={{
                marginTop: isMobile ? "20px" : "40px",
                fontSize: isMobile ? "12px" : "16px",
                color: "#374151",
              }}
            >
              <TextField
                type="text"
                variant="outlined"
                placeholder={"Enter your Email Id  here"}
                fullWidth
                name={"email"}
                disabled={loader ? true : false}
                InputProps={{
                  style: {
                    borderRadius: isMobile ? "32px" : "50px",
                    fontSize: isMobile ? "12px" : "14px",
                    fontFamily: "poppins",
                    fontWeight: 400,
                  },
                }}
                sx={{ marginTop: "20px" }}
                onChange={handleChangeEmail}
              />
            </Box>
          )}
          {isOtpSent && !isSuccess && (
            <Box>
              <Box
                sx={{
                  marginTop: "0px",
                  marginBottom: "10px",
                  fontSize: isMobile ? "12px" : "14px",
                  textAlign: "center",
                }}
              >
                We've sent a code to {mail ?? "emai"}
              </Box>
              <Box
                sx={{
                  marginTop: "30px",
                  fontSize: isMobile ? "12px" : "14px",
                }}
              >
                Enter New Password*
              </Box>
              <Box
                sx={{
                  marginTop: "10px",
                  fontSize: isMobile ? "12px" : "14px",
                }}
              >
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder={"Enter your new password here"}
                  fullWidth
                  name={"password"}
                  disabled={loader ? true : false}
                  InputProps={{
                    style: {
                      borderRadius: isMobile ? "32px" : "50px",
                      fontSize: isMobile ? "12px" : "14px",
                      fontFamily: "poppins",
                      fontWeight: 400,
                    },
                  }}
                  onChange={handleChange}
                />
              </Box>
              <Box
                sx={{
                  fontSize: isMobile ? "10px" : "12px",
                  pl: 2,
                  pr: 2,
                  mt: 1,
                  color: "#4D4D4D",
                }}
              >
                {strings.Note}
              </Box>
              <Box
                sx={{
                  marginTop: "15px",
                  fontSize: isMobile ? "12px" : "14px",
                }}
              >
                Enter OTP*
              </Box>
              <Box
                sx={{
                  marginTop: "10px",
                }}
              >
                <TextField
                  type="number"
                  variant="outlined"
                  placeholder={"Enter your OTP here"}
                  fullWidth
                  name={"otp"}
                  disabled={loader ? true : false}
                  InputProps={{
                    style: {
                      borderRadius: isMobile ? "32px" : "50px",
                      fontSize: isMobile ? "12px" : "14px",
                      fontFamily: "poppins",
                      fontWeight: 400,
                    },
                  }}
                  onChange={handleChange}
                />
              </Box>
              {timer !== 0 && (
                <div
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontSize: isMobile ? "12px" : "14px",
                  }}
                >
                  {strings.ResendIn} {formattedtimer(timer)}
                </div>
              )}

              {timer === 0 && (
                <div
                  className="message"
                  style={{
                    marginTop: "20px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: isMobile ? "12px" : "14px",
                  }}
                  onClick={handleResendOtp}
                >
                  Didn't receive OTP{" "}
                  <span style={{ color: "#6366F1" }}>{strings.SendAgain}</span>
                </div>
              )}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: isMobile ? "35px" : "40px",
              gap: "20px",
            }}
          >
            {!isSuccess &&
              (!isOtpSent ? (
                <Buttons
                  text="Send OTP"
                  variant={!mail ? "disabled" : loader ? "loader" : "primary"}
                  width={"100%"}
                  styles={{
                    fontSize: isMobile ? "12px" : "14px",
                    padding: isMobile ? "10px" : "15px",
                  }}
                  clickHandler={handleOtpSend}
                />
              ) : (
                <Buttons
                  text="Submit"
                  variant={
                    !password || !otp
                      ? "disabled"
                      : loader
                      ? "loader"
                      : "primary"
                  }
                  width={"100%"}
                  styles={{
                    fontSize: isMobile ? "12px" : "14px",
                    padding: isMobile ? "10px" : "15px",
                  }}
                  clickHandler={handleNewPassWordSet}
                />
              ))}

            {isSuccess && (
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    height: "100px",
                    width: "100px",
                    marginTop: "50px",
                    marginBottom: "50px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Success />
                </Box>
                <Box
                  sx={{
                    fontSize: isMobile ? "18px" : "24px",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  {strings.ForgetPassword.PasswordReset}
                </Box>
                <Box
                  sx={{
                    fontSize: isMobile ? "12px" : "14px",
                    textAlign: "center",
                    marginTop: isMobile ? "10px" : "20px",
                    color: "#4D4D4D",
                  }}
                >
                  {strings.ForgetPassword.Reset}
                </Box>
                <Box
                  sx={{
                    fontSize: isMobile ? "12px" : "14px",
                    textAlign: "center",
                    marginTop: isMobile ? "10px" : "20px",
                    marginBottom: isMobile ? "30px" : "40px",
                    color: "#4D4D4D",
                  }}
                >
                  {strings.ForgetPassword.ClickToLogin}
                </Box>
                <Buttons
                  variant={"primary"}
                  text={"Back to Login"}
                  width={"100%"}
                  clickHandler={handleClose}
                  styles={{
                    padding: isMobile ? "" : "15px",
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        {isMobile && (
          <Box
            sx={{
              position: "absolute",
              left: "4%",
              top: "4%",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <img src={BACK_URL} alt="back" />
          </Box>
        )}
      </div>
      {!isMobile&&<div className="back-to-website">
        <BackToPage text={"forget-password"} backToText={"login"} />
      </div>}
      <Leftbanner
        text={"forget-password"}
        backToText={"login"}
        button={
          <div className="back-to-website">
            <BackToPage text={"forget-password"} backToText={"login"} />
          </div>
        }
      />
    </div>
  );
};

export default ForgetPassword;
