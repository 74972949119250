import { API_URL } from "../constant/constants";
import AsyncLock from "../utils/AsyncLock";
import { login, logout } from "../reducers/slices/authSlice";
import api from "./api";
import { logoutUser, delay } from "../utils/Utils";

const lock = new AsyncLock();
let isTokenRefreshed = false;

const X_CLIENT_ID = "gX8MPgIyDCRd0WJwIidAaYw2A";

const getMachineId = () => {
  let machineId = localStorage.getItem("MachineId");

  if (!machineId) {
    machineId = crypto.randomUUID();
    localStorage.setItem("MachineId", machineId);
  }

  return machineId;
};

const onRequest = (config) => {
  config.headers["X-Client-Id"] = X_CLIENT_ID;
  config.headers["X-Device-Id"] = getMachineId();
  config.headers["X-Alternate-Host"] = process.env.NODE_ENV === "development" ? "localhost" : null;
  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (store, error) => {
  const originalRequest = error.config;

  if (
    error.response &&
    error.response.status === 401
  ) {
    if (error.response?.data?.error?.errorCode === "EXPIRED_AUTH_TOKEN" && !originalRequest._retry) {
      originalRequest._retry = true;

      await lock.promise;
      lock.enable();

      if (isTokenRefreshed) {
        lock.disable();
        return api(originalRequest);
      }

      try {
        console.log("Making Refresh token request")
        const response = await api.get(`${API_URL}/users/token/refresh`, {
          headers: {
            "X-Refresh-Token": localStorage.getItem("X-Refresh-Token"),
            "X-Client-Id": X_CLIENT_ID,
            "X-Device-Id": getMachineId(),
            "X-Alternate-Host": process.env.NODE_ENV === "development" ? "localhost" : null,
          },
          withCredentials: true,
        });
        store.dispatch(
          login({ refreshToken: response?.headers["x-refresh-token"] })
        );
        await delay(500);
        isTokenRefreshed = true;
        // Revert Token refreshed to original state after 7 seconds so that next time the auth token expires it calls
        // the refresh token again, Within 7 seconds all queued requests would have been dispatched
        setTimeout(() => isTokenRefreshed = false, 7000);
        lock.disable();
        return api(originalRequest);
      } catch (err) {
        isTokenRefreshed = true;
        lock.disable();
      }
    }
    console.log("Trying to logout user");
    await logoutUser(store.dispatch);
  }

  return Promise.reject(error);
};

const interceptor = (store) => {
  api.interceptors.request.use(onRequest, onRequestError);
  api.interceptors.response.use(onResponse, (err) => onResponseError(store, err));
}

export default {
  interceptor,
}
