import { createSlice } from "@reduxjs/toolkit";
import { inputTabArticle, inputTabIdea, inputTabYoutube } from "../../components/writeSection/components/createSectionComponents/Constant";

const customizeContentSlice = createSlice({
  name: "customizeContent",
  initialState: {
    ingressSource: "USER_GENERATED",
    youtubeIngressSource: "USER_GENERATED",
    articleIngressSource: "USER_GENERATED",
    requestId: crypto.randomUUID(),
    context: "",
    contextYoutube: "",
    contextArticle: "",
    narratives: {},
    youtubeNarratives: {},
    articleNarratives: {},
    selectedNews: {
      newsId: "",
      heading: "",
      description: "",
      imageUrl: "",
    },
    selectedNarrative: null,
    selectedYoutubeNarrative: null,
    selectedArticleNarrative: null,
    opinion: "",
    youtubeOpinion: "",
    articleOpinion: "",
    useNewsOrFact: false,
    youtubeUseNewsOrFact: false,
    articleUseNewsOrFact: true,
    useUserInfo: false,
    youtubeUseUserInfo: false,
    articleUseUserInfo: false,
  },
  reducers: {
    SetCustomizeContentValueToInitial: (state, action) => {
      state.contentSource = "generated";
      state.selectedNarrative = "Story";
      state.selectedYoutubeNarrative = "Story";
      state.selectedArticleNarrative = "Story";
      state.opinion = "";
      state.articleOpinion = "";
      state.youtubeOpinion = "";
      state.useNewsOrFact = false;
      state.youtubeUseNewsOrFact = false;
      state.articleUseNewsOrFact = true;
      state.useUserInfo = false;
      state.youtubeUseUserInfo = false;
      state.articleUseUserInfo = false;
    },
    SetNarratives: (state, action) => {
      const ingressSource = action.payload.ingressSource;
      const narratives = action.payload.narratives;
      if (action.payload.selectedInputType === inputTabIdea) {
        state.narratives[ingressSource] = {
          ingressSource: ingressSource,
          narratives: narratives,
        };
      } else if (action.payload.selectedInputType === inputTabYoutube) {
        state.youtubeNarratives[ingressSource] = {
          youtubeIngressSource: ingressSource,
          narratives: narratives,
        };
      } else if (action.payload.selectedInputType === inputTabArticle) {
        state.articleNarratives[ingressSource] = {
          articleIngressSource: ingressSource,
          narratives: narratives,
        };
      }
    },
    SetSelectedNarrative: (state, action) => {
      if (action.payload.selectedInputType === inputTabIdea) {
        state.selectedNarrative = action.payload;
      } else if (action.payload.selectedInputType === inputTabYoutube) {
        state.selectedYoutubeNarrative = action.payload;
      } else if (action.payload.selectedInputType === inputTabArticle) {
        state.selectedArticleNarrative = action.payload;
      }
    },
    SetOpinion: (state, action) => {
      if (action.payload.selectedInputType === inputTabIdea) {
        state.opinion = action.payload;
      } else if (action.payload.selectedInputType === inputTabYoutube) {
        state.youtubeOpinion = action.payload;
      } else if (action.payload.selectedInputType === inputTabArticle) {
        state.articleOpinion = action.payload;
      }
    },
    SetUseNewsOrFact: (state, action) => {
      if (action.payload.selectedInputType === inputTabIdea) {
        state.useNewsOrFact = action.payload;
      } else if (action.payload.selectedInputType === inputTabYoutube) {
        state.youtubeUseNewsOrFact = action.payload;
      } else if (action.payload.selectedInputType === inputTabArticle) {
        state.articleUseNewsOrFact = action.payload;
      }
    },
    SetUseUserInfo: (state, action) => {
      if (action.payload.selectedInputType === inputTabIdea) {
        state.useUserInfo = action.payload;
      } else if (action.payload.selectedInputType === inputTabYoutube) {
        state.youtubeUseUserInfo = action.payload;
      } else if (action.payload.selectedInputType === inputTabArticle) {
        state.articleUseUserInfo = action.payload;
      }
    },
    SetSelectedNews: (state, action) => {
      state.selectedNews = action.payload;
    },
    SetValuesOnIdeaGeneration: (state, action) => {
      state.requestId = action.payload.requestId;
      if (action.payload.selectedInputType === inputTabIdea) {
        state.context = action.payload.context;
        state.opinion = action.payload.opinion;
        state.useNewsOrFact = action.payload.useNewsOrFact;
        state.useUserInfo = action.payload.useUserInfo;
        state.selectedNarrative = action.payload.selectedNarrative;
        state.ingressSource = action.payload.ingressSource;
      }
      if (action.payload.selectedInputType === inputTabYoutube) {
        state.contextYoutube = action.payload.context;
        state.youtubeOpinion = action.payload.opinion;
        state.youtubeUseNewsOrFact = action.payload.useNewsOrFact;
        state.youtubeUseUserInfo = action.payload.useUserInfo;
        state.selectedYoutubeNarrative = action.payload.selectedNarrative;
        state.youtubeIngressSource = action.payload.ingressSource;
      }
      if (action.payload.selectedInputType === inputTabArticle) {
        state.contextArticle = action.payload.context;
        state.articleOpinion = action.payload.opinion;
        state.articleUseNewsOrFact = action.payload.useNewsOrFact;
        state.articleUseUserInfo = action.payload.useUserInfo;
        state.selectedArticleNarrative = action.payload.selectedNarrative;
        state.articleIngressSource = action.payload.ingressSource;
      }
      state.selectedNews = action.payload.selectedNews;
    },
  },
});

export default customizeContentSlice.reducer;
export const {
  SetCustomizeContentValueToInitial,
  SetNarratives,
  SetSelectedNarrative,
  SetOpinion,
  SetUseNewsOrFact,
  SetUseUserInfo,
  SetSelectedNews,
  SetValuesOnIdeaGeneration,
} = customizeContentSlice.actions;
