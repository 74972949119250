import { API_URL } from "../constant/constants";

export const getImageUrl = (imageUrl, cdnUrl, width, height) => {
  if (!imageUrl || !cdnUrl) {
    return null;
  }
  const resizeText = width && height ? `/fit-in/${width}x${height}` : "";

  return `${cdnUrl}${resizeText}/${imageUrl}`;
};
export const getImageFromUploadId = ({ uploadId, storageToken }) => {
  if (!uploadId || !storageToken) {
    return null;
  }
  return `${API_URL}/storage/get/${uploadId}?token=${encodeURIComponent(
    storageToken
  )}`;
};
