import { createSlice } from "@reduxjs/toolkit";

const uploadMediaSlice = createSlice({
  name: "uploadMedia",
  initialState: {
    media: [],
    resetMediaFlag: false,
  },
  reducers: {
    setMedia: (state, action) => {
      state.media = [...action.payload];
    },
    cleanMedia: (state) => {
      state.media = [];
    },
    setResetMediaFlag: (state, action) => {
      state.resetMediaFlag = action.payload;
    },
    addCarouselMedia: (state, action) => {
      const newMedia = [];
      const carouselMedia = action.payload;
      newMedia.push(carouselMedia);
      state.media = [...newMedia];
    },
  },
});
export default uploadMediaSlice.reducer;
export const { setMedia, cleanMedia, setResetMediaFlag, addCarouselMedia } =
  uploadMediaSlice.actions;
