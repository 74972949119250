import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import Buttons from "../../../sharedcomponents/custombuttons/Buttons";
import useSettingsPageApi from "../apis/settingspageApi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  onBoardingPrefencesApplyClick,
  editPrefencesApplyClick,
  selectPreferencesClick,
} from "../../../utils/events";
import ActiveDot from "../../../sharedcomponents/CombinedModalForOnboarding/Components/ActiveDot";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  borderRadius: "10px",
  boxShadow: 24,
  px: 2,
  py: 2,
  height: 681,
};

const mobStlye = {
  position: "fixed",
  bottom: "0px",
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  borderTopLeftRadius: "25px",
  borderTopRightRadius: "25px",
  boxShadow: 24,
  pl: 2,
  pt: 4,
  pr: 2,
  width: "100%",
  zIndex: 1000,
  overflowY: "scroll",
  scrollbarWidth: "thin",
  scrollbarColor: "transparent transparent",
  "&::-webkit-scrollbar": {
    width: "0.5rem",
    display: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "transparent",
  },
};

const preferencesList = [
  "Design",
  "Product",
  "Software Engineering",
  "Hiring",
  "Jobs",
  "Accounting",
  "AI / ML",
  "Entrepreneurship",
  "Content Writing",
  "Social Services",
  "Consulting",
  "Sales",
  "Education",
  "Finance",
  "Healthcare",
  "Information Technology",
  "Legal",
  "Marketing",
  "Media & communication",
  "Operations",
  "Project",
  "Planning",
  "Quality Assurance",
  "Real Estate",
  "Startups",
  "Funding",
  "Healing",
  "Neuro-Linguistic",
  "Relationship",
  "Resume Writing",
  "Job Hunting",
  "Linkedin Profile Optimization",
  "Career Counseling",
  "Local SEO",
  "Optometry",
  "HR Tech"
];

const PreferencesChip = ({ selected, text, clickHandler }) => {
  const isMobile = useSelector((state) => state?.device?.isMobileDevice);
  const clickHandle = () => {
    clickHandler(text);
  };
  return (
    <Box
      onClick={clickHandle}
      style={{
        display: "flex",
        alignItems: "center",
        padding: isMobile ? "5px 12px" : "6px 14px",
        borderRadius: "40px",
        background: selected
          ? "rgba(99, 102, 241, 1)"
          : "rgba(247, 247, 247, 1)",
        gap: isMobile ? "8px" : "10px",
        cursor: "pointer",
      }}
    >
      <Box
        style={{
          height: isMobile ? "19px" : "22px",
          width: isMobile ? "19px" : "22px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: selected ? "rgba(99, 102, 241, 1)" : "#fff",
          border: "1px solid rgba(228, 228, 228, 1)",
          borderRadius: "40px",
        }}
      >
        {selected && (
          <img src="/image/icons/checkMark.svg" width={"12px"} alt="check" />
        )}
      </Box>
      <Typography
        sx={{
          fontSize: isMobile ? "11px" : "14px",
          fontFamily: "Poppins",
          letterSpacing: "0.15px",
          color: selected ? "#fff" : "#000",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const PreferencesModal = ({
  open,
  handleClose,
  btnText,
  btnMainTxt = "Apply",
}) => {
  //current preffered topics of the user
  const prefferedTopicsData = useSelector(
    (state) => state.userData?.userAttributes?.preferredTopics ?? []
  );
  const [selectedPreferences, setSelectedPreferences] = useState(
    prefferedTopicsData.filter((item) => preferencesList.includes(item)) ?? []
  );
  const isMobile = useSelector((state) => state?.device?.isMobileDevice);

  //manual preferences added by the user
  const [manual, setManual] = useState(
    prefferedTopicsData.filter((item) => !preferencesList.includes(item)) ?? []
  );
  //console.log(manual, "manual");
  const [input, setInput] = useState("");
  const [loader, setLoader] = useState(false);
  const [toastText, setToastText] = useState("");

  //useEffect to reset the toast text
  useEffect(() => {
    if (toastText !== "") {
      toast(toastText);
      setToastText("");
    }
  }, [toastText]);

  //useEffect to rerender when the there is any change in the preffered topics
  useEffect(() => {
    setSelectedPreferences(
      prefferedTopicsData.filter((item) => preferencesList.includes(item)) ?? []
    );
    setManual(
      prefferedTopicsData.filter((item) => !preferencesList.includes(item)) ??
        []
    );
  }, [prefferedTopicsData]);

  //api call to update the preffered topics
  const { prefferedTopics } = useSettingsPageApi();

  //function to select the preferences
  const selectPreference = (name) => {
    if (selectedPreferences.includes(name)) {
      setSelectedPreferences(
        selectedPreferences.filter((item) => item !== name)
      );
      selectPreferencesClick({ filter_name: name, selected: false });
    } else {
      setSelectedPreferences([...selectedPreferences, name]);
      selectPreferencesClick({ filter_name: name, selected: true });
    }
  };

  //  console.log(selectedPreferences, "selectedPreferences from modal");

  //function to insert the manual preferences
  const insertPreferences = () => {
    if (input === "" && selectedPreferences.length > 0) {
      prefferedTopics({
        topics: selectedPreferences,
        setLoader,
        handleClose,
        setToastText,
      });
      return;
    }
    const dataArray = [...new Set(input.split(",").map((item) => item.trim()))];
    const all = [...manual, ...dataArray];
    const uniqueArray = [...new Set(all)];
    setManual([...uniqueArray]);
    setInput("");

    if (selectedPreferences.length > 0 || uniqueArray.length > 0) {
      prefferedTopics({
        topics: [...selectedPreferences, ...uniqueArray],
        setLoader,
        handleClose,
        setToastText,
      });
    }
    // ga event
    if (btnText === "cancel") {
      editPrefencesApplyClick([...selectedPreferences, ...uniqueArray]);
    } else {
      onBoardingPrefencesApplyClick([...selectedPreferences, ...uniqueArray]);
    }
  };

  //function to remove the manual preferences
  const removeManualPreferences = (name) => {
    setManual(manual.filter((item) => item !== name));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={isMobile ? mobStlye : style}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
            }}
          >
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "rgba(18,18,18,1)",
                fontFamily: "Poppins",
                fontSize: isMobile ? "24px" : "35px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              What topics do you want to talk about?
            </Typography>
            <Box
              id="transition-modal-description"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: isMobile ? "8px " : "0px 18px",
                borderRadius: isMobile ? "8px" : "20px",
                gap: "16px",
                marginRight: "48px",
                marginBottom: "12px",
                width: isMobile ? "100%" : "auto",
                marginTop: isMobile ? "10px" : "16px",
                background:
                  "linear-gradient(97.95deg, #E4E8F7 4.9%, #E2F0FF 97.51%)",
                padding: isMobile ? "8px 12px" : "20px 18px",
              }}
            >
              {/* <img
                src="/image/preferences.svg"
                width={isMobile ? "46px" : "70px"}
                alt="preference"
              /> */}
              <span
                style={{
                  color: "rgba(99, 102, 241, 1)",
                  fontSize: isMobile ? "10px" : "14px",
                  lineHeight: isMobile ? "16px" : "20px",
                  textAlign: "center",
                }}
              >
                Select upto <span style={{fontWeight:"600",color:"#6366F1"}}>10</span> topics of your interest. Your preferences help us
                provide relevant content ideas for your posts
              </span>
            </Box>
            <Box
              className={"thin-scrollbar"}
              style={{
                padding: "12px",
                paddingRight: "30px",
                paddingLeft: "0px",
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                overflow: "hidden",
                maxHeight: isMobile ? "260px" : "300px",
                overflowY: "auto",
              }}
            >
              {preferencesList.map((item, index) => (
                <PreferencesChip
                  text={item}
                  key={index}
                  selected={selectedPreferences.includes(item) ? true : false}
                  clickHandler={selectPreference}
                />
              ))}
              {manual &&
                manual.map((item, index) => (
                  <PreferencesChip
                    text={item}
                    key={index}
                    selected={true}
                    clickHandler={removeManualPreferences}
                  />
                ))}
            </Box>
            <Typography
              sx={{
                fontSize: isMobile ? "12px" : "16px",
                fontFamily: "Poppins",
                color: "rgba(55, 65, 81, 1)",
                marginTop: isMobile ? "10px" : "16px",
              }}
            >
              Can’t find your interest here? Please specify
            </Typography>
            <input
              type="text"
              placeholder="Enter your interests, followed by a comma"
              style={{
                padding: isMobile ? "6px 8px" : "10px 12px",
                border: "1px solid rgba(213, 214, 219, 1)",
                borderRadius: "40px",
                width: "95%",
                color: "#1f1f1f",
                margin: "6px 30px 10px 4px",
                outline: "none",
                fontSize: isMobile ? "12px" : "14px",
                disabled: loader ? true : false,
              }}
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
              }}
            />

            {/* <Typography
              sx={{
                fontSize: isMobile ? "10px" : "12px",
                fontFamily: "Poppins",
                color: "rgba(77, 77, 77, 1)",
                padding: "0px 48px 24px 30px",
              }}
            >
              We are taking your interests for a more personalized experience.
              You can, however, still enter any topic to create posts and our
              super powerful tool should be able to provide amazing results for
              you.
            </Typography> */}
            <Box
              sx={{
                marginTop: isMobile ? " " : "20px",
              }}
            >
              {!isMobile && <Divider />}
              <Box
                sx={{
                  margin: isMobile ? "0px" : "16px 0px 0px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: isMobile ? "0px" : "18px",
                  paddingRight: isMobile ? "0px" : "18px",
                  position: isMobile ? "sticky" : "auto",
                  bottom: 0,
                  background: isMobile ? "#fff" : "none",
                  pb: isMobile ? "16px" : "0px",
                  borderTop: isMobile
                    ? "1px solid rgba(228, 228, 228, 1)"
                    : "none",
                  pt: isMobile ? "16px" : "0px",
                }}
              >
                {/* {btnMainTxt === "Next Step" && <Box>
                  <ActiveDot active={1} />
                </Box>} */}
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Buttons
                    text={btnText}
                    variant={loader ? "disabled" : "secondary"}
                    background={"none"}
                    clickHandler={handleClose}
                    styles={{
                      border: "none",
                      color: "rgba(99, 102, 241, 1)",
                      fontSize: isMobile ? "12px" : "16px",
                    }}
                  />

                  <Buttons
                    text={btnMainTxt}
                    variant={
                      loader
                        ? "loader"
                        : selectedPreferences.length > 0 ||
                          input !== "" ||
                          manual.length > 0
                        ? "primary"
                        : "disabled"
                    }
                    clickHandler={insertPreferences}
                    styles={{
                      fontSize: isMobile ? "12px" : "16px",
                      width: loader ? "100px" : "auto",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PreferencesModal;
