import { createSlice } from "@reduxjs/toolkit";

const paymentOfferTimer = createSlice({
  name: "paymentOfferTimer",
  initialState: {
    openTimer: null,
    closeTimer:null,
  },
  reducers: {
    setOpenTimer: (state, action) => {
      state.openTimer = action.payload;
    },
    setCloseTimer: (state, action) => {
      state.closeTimer = action.payload;
    },
    unsetTimer: (state) => {
      state.openTimer = null;
      state.closeTimer = null;
    },
  },
});

export default paymentOfferTimer.reducer;
export const { setOpenTimer, unsetTimer,setCloseTimer } = paymentOfferTimer.actions;
