const THUNDER_URL = "/image/others/thunder-home.svg";
const THUMBS_URL = "/image/others/thumbs-home.svg";
const CURATED_URL = "/image/others/curated-home.svg";
const OWN_IDEA_URL = "/image/others/own-idea.svg";
const YOUTUBE_URL = "/image/others/youtube.svg";
const ARTICLE_URL = "/image/others/generate-article.svg";

export const featuresDisplayContent = [
  {
    url: "",
    img: THUNDER_URL,
    text: "Trending News",
    altText: "Trending News Icon",
    url: "/inspiration/trendingNews",
  },
  {
    url: "",
    img: THUMBS_URL,
    text: "Recommended Ideas",
    altText: "Recommended Ideas Icon",
    url: "/inspiration/recommendedIdeas",
  },
  {
    url: "",
    img: CURATED_URL,
    text: "Curated Posts",
    altText: "Curated Posts Icon",
    url: "/inspiration/curatedPosts",
  },
];

export const inputTypeContent = [
  {
    label: "Your Idea",
    mobileLabel: "Idea",
    ind: 0,
    img: OWN_IDEA_URL,
    altText: "Own Idea Icon",
    identifier: "idea",
  },

  {
    label: "Public Article Link",
    mobileLabel: "Article",
    ind: 1,
    img: ARTICLE_URL,
    altText: "Article Icon",
    identifier: "article",
  },
  {
    label: "YouTube Video",
    mobileLabel: "YouTube",
    ind: 2,
    img: YOUTUBE_URL,
    altText: "Youtube Icon",
    identifier: "youtube",
  },
];

export const inputTabIdea = "idea";
export const inputTabArticle = "article";
export const inputTabYoutube = "youtube";


