import { createSlice } from "@reduxjs/toolkit";

const carouselSlice = createSlice({
  name: "carousel",
  initialState: {
    showSaveAndLeaveModal: false,
    pathToExit: "",
  },
  reducers: {
    setShowSaveAndLeaveModal: (state, action) => {
      state.showSaveAndLeaveModal = action.payload;
    },
    setPathToExit: (state, action) => {
      state.pathToExit = action.payload;
    },
  },
});

export default carouselSlice.reducer;
export const { setShowSaveAndLeaveModal, setPathToExit } =
  carouselSlice.actions;
