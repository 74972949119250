import { createSlice } from "@reduxjs/toolkit";

const deviceSlice = createSlice({
  name: "device",
  initialState: {
    isMobileDevice: true,
    isDesktopDevice: false,
    coachMarkNumber: -1,
    isSideBarExpanded: true,
  },
  reducers: {
    setDeviceMobile: (state, action) => {
      state.isMobileDevice = true;
      state.isDesktopDevice = false;
    },
    setDeviceDesktop: (state, action) => {
      state.isMobileDevice = false;
      state.isDesktopDevice = true;
    },
    setCoachMarkNumber: (state, action) => {
      state.coachMarkNumber = action.payload;
    },
    setIsSidebBarExpanded: (state, action) => {
      state.isSideBarExpanded = action.payload;
    },
  },
});

export default deviceSlice.reducer;
export const {
  setDeviceMobile,
  setDeviceDesktop,
  setCoachMarkNumber,
  setIsSidebBarExpanded,
} = deviceSlice.actions;
