import { ArrowBack, Edit } from "@mui/icons-material";
import "./Buttons.scss";
import Spinner from "../Spinner/Spinner";

const Buttons = ({
  text,
  variant,
  clickHandler,
  startIcon,
  endIcon,
  background,
  width,
  size,
  styles,
  isDisable,
  isLoading,
  iconWidth,
  iconStyle,
}) => {
  const getButtonStyle = () => {
    switch (variant) {
      case "primary":
        return "primary ";
      case "secondary":
        return "secondary";
      case "outlined":
        return "outlined";
      case "disabled":
        return "disabled";
      case "gradient":
        return isLoading ? "loader" : "gradient";
      case "darkBackground":
        return "darkBackground";
      case "loader":
        return "loader";
      default:
        return "";
    }
  };
  const getButtonIcon = () => {
    switch (startIcon) {
      case "back":
        return "backArrow.svg";
      case "edit":
        return "edit.svg";
      case "linkedin":
        return "linkedin.svg";
      case "draft":
        return "saveDraft.svg";
      case "draftActive":
        return "saveDraftActive.svg";
      case "arrowTop":
        return "arrowTop.svg";
      case "reload":
        return "reload.svg";
      case "UploadBlack":
        return "UploadBlack.svg";
      default:
        return "";
    }
  };

  const getButtonIcon2 = () => {
    switch (endIcon) {
      case "back":
        return "backArrow.svg";
      case "edit":
        return "edit.svg";
      case "linkedin":
        return "linkedin.svg";
      case "linkedinBlack":
        return "linkedinBlack.svg";
      case "magicPen":
        return "magicPen.svg";
      case "saveDraft":
        return "saveDraft.svg";
      case "saveDraftActive":
        return "saveDraftActive.svg";
      case "nextArrow":
        return "nextArrow.svg";
      case "ideas":
        return "ideas-bulb.svg";
      default:
        return "";
    }
  };

  return (
    <button
      className={
        `button ${isDisable ? "btn-disabled " : ""}` + getButtonStyle()
      }
      onClick={() => {
        console.log("clicked", isDisable);
        clickHandler();
      }}
      style={{
        background: background,
        width: width,
        ...styles,
      }}
      disabled={
        isDisable || variant === "disabled" || variant === "loader" || isLoading
      }
      // variant === "disabled" || variant === "loader" || isDisable ? true : false
    >
      {startIcon && (
        <img
          style={{ width: iconWidth ? iconWidth : "14px", ...iconStyle }}
          src={"/image/icons/" + getButtonIcon()}
          alt={getButtonIcon() + "-icon"}
        />
      )}
      {(variant === "loader" || isLoading) && (
        <div style={{ height: "36px", padding: "0px" }}>
          <Spinner />
        </div>
      )}
      {variant !== "loader" && !isLoading && text}

      {endIcon && variant !== "loader" && !isLoading && (
        <img
          style={{ width: iconWidth ? iconWidth : "18px", ...iconStyle }}
          src={"/image/icons/" + getButtonIcon2()}
          alt={getButtonIcon2() + "-icon"}
        />
      )}
    </button>
  );
};

export default Buttons;
