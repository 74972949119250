import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BackToPage = ({ backToText, text }) => {
  //custom hooks
  const isMobile = useSelector((state) => state?.device?.isMobileDevice);
  const navigate = useNavigate();

  //image urls
  const BACK_URL = "/image/icons/backArrow.svg";
  const PAGE_URL = "https://reachifyme.com/";

  return (
    <div
      style={{
        position: "relative",
        top: isMobile ? "none" : "20px",
        left: isMobile ? "none" : "20px",
        width: "500px",
        padding: isMobile ? "none" : "10px 20px",
        backgroundColor: isMobile ? "none" : "white",
        borderRadius: "50px",
        border: isMobile ? "none" : "1px solid white",
        width: "100%",
        textAlign: "center",
        display: isMobile ? "block" : "block",
        cursor: "pointer",
        border: isMobile ? "none" : "1px solid #E5E5E5",
        boxShadow: isMobile ? "none" : "0px 4px 4px rgba(0, 0, 0, 0.10)",
        borderRadius: isMobile ? "none" : "25px",
      }}
      onClick={() => {
        if (text === "login") {
          window.location.href = PAGE_URL;
        } else if (text === "verify-mail") {
          window.location.reload();
        } else {
          navigate("/login");
        }
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <img src={BACK_URL} alt="back" />

        <div style={{ display: isMobile && "none" }}>Back to {backToText}</div>
      </div>
    </div>
  );
};

export default BackToPage;
