import { Avatar } from "@mui/material";
import React from "react";

const ProfilePicture = ({
  getImageUrl,
  profilePicturePath,
  cdnUrl,
  linkedinTrue = true,
  avatarStyle,
  profileBoxStyle,
}) => {
  return (
    <div className="profile-img-component" style={{ ...profileBoxStyle }}>
      <Avatar
        alt={"profile"}
        src={getImageUrl(profilePicturePath, cdnUrl, 80, 80)}
        sx={{
          bgcolor: "#FFC700",
          width: "25px",
          height: "25px",
          ...avatarStyle,
        }}
      />
      {linkedinTrue && (
        <img
          className="linkedin-connected"
          src="/image/icons/linkedin.svg"
          alt="profile"
          width="10px"
        />
      )}
    </div>
  );
};

export default ProfilePicture;
