import { API_URL } from "../../../constant/constants";
import useRestClient from "../../../utils/RestClient";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../reducers/slices/userDataSlice";
import { setStorageToken } from "../../../reducers/slices/storageSlice";
import {
  setLinkedinConnectData,
  setLinkedinIds,
} from "../../../reducers/slices/linkedinConnectSlice";
import { SetSubscriptions } from "../../../reducers/slices/paymentSlice";

export const emptyUserAttribute = {
  firstName: "",
  middleName: null,
  lastName: null,
  phoneNumber: null,
  countryCode: null,
  countryIso: null,
  profileImageStorageId: "",
  preferredTopics: [],
};

const useProtectedRouteApis = () => {
  const { get } = useRestClient();
  const dispatch = useDispatch();

  const getUserDetails = () => {
    return new Promise((resolve, reject) => {
      get(`${API_URL}/user`, {
        data: {},
        onSuccess: (response) => {
          dispatch(
            setUserData({
              userId: response?.data?.data?.user?.userId,
              email: response?.data?.data?.user?.email,
              isVerified: response?.data?.data?.user?.isVerified,
              userAttribute: response?.data?.data?.userAttribute,
              hasPassword: response?.data?.data?.user?.hasPassword,
            })
          );
          resolve(true);
        },
        onError: (response) => {
          reject(false);
        },
      });
    });
  };
  const getStorageToken = () => {
    return new Promise((resolve, reject) => {
      get(`${API_URL}/storage/token`, {
        data: {},
        onSuccess: (response) => {
          const rs = response?.data?.data;
          dispatch(
            setStorageToken({
              token: rs.token,
              expiryTime: rs.expiryTime,
              baseUrl: rs.baseUrl,
              cdnUrl: rs.cdnUrl,
            })
          );
          resolve(true);
        },
        onError: (response) => {
          reject(false);
        },
      });
    });
  };

  const getLinkedInProfiles = () => {
    return new Promise((resolve, reject) => {
      get(`${API_URL}/platform/linkedin/profiles`, {
        data: {},
        onSuccess: (response) => {
          if (response.data.data.length > 0) {
            console.log("proctedRoute")
            const profile = response?.data?.data[0];
            const organizations = profile.organizations;
            const combinedArray = [profile, ...organizations];
            dispatch(setLinkedinConnectData(combinedArray));
            dispatch(setLinkedinIds([response?.data?.data[0]?.linkedInId]));
          }
          resolve(true);
        },
        onError: (response) => {
          reject(false);
        },
      });
    });
  };

  const getActiveUserSubscription = () => {
    return new Promise((resolve, reject) => {
      get(`${API_URL}/subscription/user/active-plan`, {
        data: {},
        onSuccess: (response) => {
          dispatch(
            SetSubscriptions({
              ...response?.data?.data,
              currentTime: response?.data?.currentTime,
            })
          );
          resolve(true);
        },
        onError: (response) => {
          reject(false);
        },
      });
    });
  };

  return {
    getUserDetails,
    getStorageToken,
    getLinkedInProfiles,
    getActiveUserSubscription,
  };
};

export default useProtectedRouteApis;
