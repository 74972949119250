import React from "react";
import ReactGA from "react-ga4";

// Login Page

const continueWithGoogleClick = () =>
  ReactGA.event("continue_with_google_click", {
    category: "click",
  });

const loginWithEmailClick = () =>
  ReactGA.event("login_with_email_click", {
    category: "click",
  });

const loginSuccesfull = (loginData) =>
  ReactGA.event("login_success", {
    category: "api",
    ...loginData,
  });

const loginFailed = (loginData) =>
  ReactGA.event("login_failed", {
    category: "api",
    ...loginData,
  });

// Post Login
const setUserId = (userId) => ReactGA.set({ userId });

// Connect with LinkedIn Page
const connectWithLinkedInClick = () =>
  ReactGA.event("connect_with_linkedin_click", {
    category: "click",
  });

const connectLikedInCancelClick = () =>
  ReactGA.event("connect_linkedin_cancel_click", {
    category: "click",
  });

const connectWithLinkedInSuccess = (linkedInData) =>
  ReactGA.event("linkedin_connection_successful", {
    category: "api",
    ...linkedInData,
  });

const connectWithLinkedInFailed = (linkedInData) =>
  ReactGA.event("linkedin_connection_failed", {
    category: "api",
    ...linkedInData,
  });

// Create post screen

const generatePostClick = (query) => {
  ReactGA.event("create_post_click", {
    category: "click",
    ...query,
  });
};

const generatePostSuccess = (query) =>
  ReactGA.event("generate_post_sucess", {
    category: "api",
    ...query,
  });

const generatePostFailed = (query) =>
  ReactGA.event("generate_post_failed", {
    category: "api",
    ...query,
  });

const attachImageClick = (data) =>
  ReactGA.event("attach_image_click", {
    category: "click",
    ...data,
  });

const previewAndPostClick = () =>
  ReactGA.event("preview_and_post_click", {
    category: "click",
  });

const saveAsDraftClick = (data) =>
  ReactGA.event("save_as_draft_click", {
    category: "click",
    ...data,
  });

const createTakeThisIdeaClick = (data) =>
  ReactGA.event("create_take_this_idea_click", {
    category: "click",
    ...data,
  });

const createGenerateMoreIdeasClick = () =>
  ReactGA.event("create_generate_more_ideas_click", {
    category: "click",
  });

const createModuleReadMoreClick = () =>
  ReactGA.event("create_module_read_more_click", {
    category: "click",
  });

const createModuleReadLessClick = () =>
  ReactGA.event("create_module_read_less_click", {
    category: "click",
  });

const createModuleSeeDraftClick = () =>
  ReactGA.event("create_module_see_draft_click", {
    category: "click",
  });

//all post drafts sections events

const editDraftClick = () =>
  ReactGA.event("edit_draft_click", {
    category: "click",
  });

const quickViewClick = (data) =>
  ReactGA.event("quick_view_click", {
    category: "click",
    ...data,
  });

const quickViewEditClick = () =>
  ReactGA.event("quick_view_edit_click", {
    category: "click",
  });

//all post scheduled section events
const allPostRescheduleClick = () =>
  ReactGA.event("reschedule_click", {
    category: "click",
  });

const allPostCancelScheduleClick = () =>
  ReactGA.event("cancel_schedule_click", {
    category: "click",
  });

const allPostRescheduleApplyClick = () =>
  ReactGA.event("reschedule_apply_click", {
    category: "click",
  });

const allPostRescheduleCancelClick = () =>
  ReactGA.event("reschedule_cancel_click", {
    category: "click",
  });

const allPostCancelScheduleCancelClick = () =>
  ReactGA.event("cancel_schedule_cancel_click", {
    category: "click",
  });

const allPostCancelScheduleRescheduleClick = () =>
  ReactGA.event("cancel_schedule_reschedule_click", {
    category: "click",
  });

//home page events
const homeallPostClick = () =>
  ReactGA.event("all_post_click", {
    category: "click",
  });

const homeDraftClick = () =>
  ReactGA.event("all_post_draft_click", {
    category: "click",
  });

const homeScheduledClick = () =>
  ReactGA.event("all_post_scheduled_click", {
    category: "click",
  });

const homeRecommendedIdeasClick = () =>
  ReactGA.event("inspiration_recommendedIdeas_click", {
    category: "click",
  });

const homeLogoutClick = () =>
  ReactGA.event("logout_click", {
    category: "click",
  });

const homeSettingsClick = () =>
  ReactGA.event("settings_click", {
    category: "click",
  });

//prefrences flow events
const onBoardingPrefencesApplyClick = (data) =>
  ReactGA.event("onboarding_prefences_apply_click", {
    category: "click",
    ...data,
  });

const onBoardingPrefencesSkipClick = () =>
  ReactGA.event("onboarding_prefences_skip_click", {
    category: "click",
  });

const editPrefencesApplyClick = (data) =>
  ReactGA.event("edit_prefences_apply_click", {
    category: "click",
    ...data,
  });

const editPreferencesCancelClick = () =>
  ReactGA.event("edit_prefences_cancel_click", {
    category: "click",
  });

//settings page events
const settingsChangePasswordClick = () =>
  ReactGA.event("settings_change_password_click", {
    category: "click",
  });

const settingsSetPassWordClick = () =>
  ReactGA.event("settings_set_password_click", {
    category: "click",
  });

const settingsDisconnectLinkedInClick = () =>
  ReactGA.event("settings_disconnect_linkedin_click", {
    category: "click",
  });

const settingsConnectLinkedInClick = () =>
  ReactGA.event("settings_connect_linkedin_click", {
    category: "click",
  });

//Edit flow events
const editFlowGoBackClick = () =>
  ReactGA.event("edit_flow_go_back_click", {
    category: "click",
  });

const editFlowAttachMediaClick = () =>
  ReactGA.event("edit_flow_attach_photo_click", {
    category: "click",
  });

//Preview and post events
const previewGoBackClick = () =>
  ReactGA.event("preview_go_back_click", {
    category: "click",
  });

const schedulePostClick = () =>
  ReactGA.event("schedule_post_click", {
    category: "click",
  });

const schedulePostSuccess = () =>
  ReactGA.event("schedule_post_successful", {
    category: "api",
  });

const schedulePostFailed = () =>
  ReactGA.event("schedule_post_failed", {
    category: "api",
  });

const previewConnectLinkedInShown = () =>
  ReactGA.event("preview_connect_linkedin_shown", {
    category: "click",
  });

const previewScheduleApplyClick = (data) =>
  ReactGA.event("preview_schedule_apply_click", {
    category: "click",
    ...data,
  });

const previewScheduleCancelClick = () =>
  ReactGA.event("preview_schedule_cancel_click", {
    category: "click",
  });

const previewScheduleConfirmNewPostClick = () =>
  ReactGA.event("preview_schedule_confirm_new_post_click", {
    category: "click",
  });

const previewScheduleConfirmAllPostClick = () =>
  ReactGA.event("preview_schedule_confirm_all_post_click", {
    category: "click",
  });

const postNowClick = (postNowData) =>
  ReactGA.event("post_now_click", {
    category: "click",
    ...postNowData,
  });

const postNowSuccess = (postNowSuccessData) =>
  ReactGA.event("post_now_successful", {
    category: "api",
    ...postNowSuccessData,
  });

const postNowFailed = () =>
  ReactGA.event("post_now_failed", {
    category: "api",
  });

const checkOnLinkedInClick = () =>
  ReactGA.event("check_on_linkedin_click", {
    category: "click",
  });

const writeNewPostClick = () =>
  ReactGA.event("write_new_post_click", {
    category: "click",
  });

//recent events and treding section events
const recentIdeaClick = (data) =>
  ReactGA.event("recent_idea_click", {
    category: "click",
    ...data,
  });

const trendingIdeaClick = (data) =>
  ReactGA.event("trending_idea_click", {
    category: "click",
    ...data,
  });

//copy events
const copyClick = (data) =>
  ReactGA.event("copy_click", {
    category: "click",
    ...data,
  });

//inspiration page events
const inspirationSectionClick = () =>
  ReactGA.event("inspiration_section_click", {
    category: "click",
  });

//load more click
const loadMoreClick = (data) =>
  ReactGA.event("load_more_click", {
    category: "click",
    ...data,
  });

//add preference click
const addPreferenceClick = (data) =>
  ReactGA.event("add_preference_click", {
    category: "click",
    ...data,
  });

const editPreferenceClick = (data) =>
  ReactGA.event("edit_preference_click", {
    category: "click",
    ...data,
  });

const checkInspirationClick = (data) =>
  ReactGA.event("check_inspiration_click", {
    category: "click",
    ...data,
  });

//edit section regenetate section
const editFlowRegenerateClick = (data) =>
  ReactGA.event("edit_flow_regenerate_click", {
    category: "click",
    ...data,
  });

//post-now modal copy link to clipboard
const copyLinkToClipboard = (data) =>
  ReactGA.event("copy_link_to_clipboard", {
    category: "click",
    ...data,
  });

//post-now modal share on mobile
const shareOnMobile = (data) =>
  ReactGA.event("share_on_mobile", {
    category: "click",
    ...data,
  });

//feedback url click events
const feedbackUrlClick = () =>
  ReactGA.event("feedback_url_click", {
    category: "click",
  });

//Edit page events
const addHookClick = (data) =>
  ReactGA.event("add_hook_click", {
    category: "click",
  });

const addCTAClick = (data) =>
  ReactGA.event("add_cta_click", {
    category: "click",
  });

const insertHookClick = () =>
  ReactGA.event("insert_hook_click", {
    category: "click",
  });

const insertCTAClick = () =>
  ReactGA.event("insert_cta_click", {
    category: "click",
  });

const cancelHookClick = () =>
  ReactGA.event("cancel_hook_click", {
    category: "click",
  });

const cancelCTAClick = () =>
  ReactGA.event("cancel_cta_click", {
    category: "click",
  });

const regenetateHookClick = () =>
  ReactGA.event("regenerate_hook_click", {
    category: "click",
  });

const regrenerateCTAClick = () =>
  ReactGA.event("regenerate_cta_click", {
    category: "click",
  });

const addImageClick = () =>
  ReactGA.event("add_image_click", {
    category: "click",
  });

const addVideoClick = () =>
  ReactGA.event("add_video_click", {
    category: "click",
  });

const addDocumentClick = () =>
  ReactGA.event("add_document_click", {
    category: "click",
  });

const addGoogleImagesClick = () =>
  ReactGA.event("add_google_images_click", {
    category: "click",
  });

const searchGoogleImage = (data) =>
  ReactGA.event("search_google_image", {
    category: "click",
    ...data,
  });

const googleSearchLoadMoreClick = (data) =>
  ReactGA.event("google_search_load_more_click", {
    category: "click",
    ...data,
  });

const mediaUploadedSuccessful = (data) =>
  ReactGA.event("media_uploaded_successful", {
    category: "api",
    ...data,
  });

const mediaUploadedFailed = (data) =>
  ReactGA.event("media_uploaded_failed", {
    category: "api",
    ...data,
  });

//open editor events
const writeCustomPostClick = () =>
  ReactGA.event("write_custome_post_click", {
    category: "click",
  });

//select preferences events
const selectPreferencesClick = (data) =>
  ReactGA.event("preference_filter_click", {
    category: "click",
    ...data,
  });

const GenerateCarouselClick = (carouselData) =>
  ReactGA.event("generate_carousel_click", {
    category: "click",
    ...carouselData,
  });

const SaveCarouselClick = (carouselData) =>
  ReactGA.event("save_carousel_click", {
    category: "click",
    ...carouselData,
  });

const DownloadCarouselClick = (carouselData) =>
  ReactGA.event("download_carousel_click", {
    category: "click",
    ...carouselData,
  });

const AddCarouselClick = (carouselData) =>
  ReactGA.event("add_carousel_click", {
    category: "click",
    ...carouselData,
  });

export {
  continueWithGoogleClick,
  loginWithEmailClick,
  loginSuccesfull,
  loginFailed,
  setUserId,
  connectWithLinkedInClick,
  connectWithLinkedInSuccess,
  connectWithLinkedInFailed,
  generatePostClick,
  generatePostSuccess,
  generatePostFailed,
  attachImageClick,
  previewAndPostClick,
  postNowClick,
  postNowSuccess,
  postNowFailed,
  checkOnLinkedInClick,
  writeNewPostClick,
  saveAsDraftClick,
  schedulePostFailed,
  schedulePostSuccess,
  schedulePostClick,
  editDraftClick,
  quickViewClick,
  quickViewEditClick,
  allPostRescheduleClick,
  allPostCancelScheduleClick,
  allPostRescheduleApplyClick,
  allPostRescheduleCancelClick,
  allPostCancelScheduleCancelClick,
  allPostCancelScheduleRescheduleClick,
  homeallPostClick,
  homeDraftClick,
  homeScheduledClick,
  homeRecommendedIdeasClick,
  homeLogoutClick,
  homeSettingsClick,
  onBoardingPrefencesApplyClick,
  onBoardingPrefencesSkipClick,
  editPrefencesApplyClick,
  editPreferencesCancelClick,
  connectLikedInCancelClick,
  settingsChangePasswordClick,
  settingsSetPassWordClick,
  settingsDisconnectLinkedInClick,
  settingsConnectLinkedInClick,
  editFlowGoBackClick,
  editFlowAttachMediaClick,
  previewGoBackClick,
  previewConnectLinkedInShown,
  previewScheduleApplyClick,
  previewScheduleCancelClick,
  previewScheduleConfirmNewPostClick,
  previewScheduleConfirmAllPostClick,
  createTakeThisIdeaClick,
  createGenerateMoreIdeasClick,
  createModuleReadMoreClick,
  createModuleReadLessClick,
  createModuleSeeDraftClick,
  recentIdeaClick,
  trendingIdeaClick,
  copyClick,
  inspirationSectionClick,
  loadMoreClick,
  addPreferenceClick,
  editPreferenceClick,
  checkInspirationClick,
  editFlowRegenerateClick,
  copyLinkToClipboard,
  shareOnMobile,
  feedbackUrlClick,
  addHookClick,
  addCTAClick,
  insertHookClick,
  insertCTAClick,
  cancelHookClick,
  cancelCTAClick,
  regenetateHookClick,
  regrenerateCTAClick,
  addImageClick,
  addVideoClick,
  addDocumentClick,
  addGoogleImagesClick,
  searchGoogleImage,
  googleSearchLoadMoreClick,
  mediaUploadedSuccessful,
  mediaUploadedFailed,
  writeCustomPostClick,
  selectPreferencesClick,
  GenerateCarouselClick,
  SaveCarouselClick,
  DownloadCarouselClick,
  AddCarouselClick,
};
